import _mock from '../_mock';
import { randomInArray } from '../utils';

const today = new Date(); // get today's date

const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
const formattedDate = today.toLocaleDateString('en-GB', options); 

export const _applicantList = [...Array(18)].map((_, index) => ({
    id: _mock.id(index),
    avatarUrl: _mock.image.avatar(index),
    name: _mock.name.fullName(index),
    email: _mock.email(index),
    phoneNumber: _mock.phoneNumber(index),
    experience: '1-3 yrs.',
    address: '908 Jack Locks',
    technologies: 'ReactJs',
    gender: _mock.gender(index),
    location: _mock.location(index),
    date: formattedDate,
    country: _mock.address.country(index),
    state: 'Virginia',
    zipCode: '85807',
    company: _mock.company(index),
    isVerified: _mock.boolean(index),
    status: randomInArray(['active', 'banned']),
    role: _mock.role(index),
    resume: '/assets/pdf/resume-sample.pdf' ,
    linkedIn:'https://in.linkedin.com'
  }));