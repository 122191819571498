import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {/* <img src="../../assets/icon.svg" alt="logo_icon" /> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 270.83 291.62">
        <defs>
          <linearGradient id="linear-gradient" y1="223.8" x2="270.83" y2="223.8" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor={PRIMARY_MAIN} />
            <stop offset="1" stopColor={PRIMARY_DARK} />
          </linearGradient>

          <linearGradient id="linear-gradient-2" x1="48.04" y1="68.2" x2="218.26" y2="68.2">
            <stop offset="0" stopColor={PRIMARY_MAIN} />
            <stop offset="1" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g id="Layer_2" data-name="Layer 2" fill={PRIMARY_MAIN}>
          <g id="Layer_1-2" data-name="Layer 1" fill={PRIMARY_MAIN}>
            <path
              className="cls-1"
              fill="url(#linear-gradient)"
              d="M246.91,291.62l-42.13-71.84a27,27,0,0,0-2.79,2.4c-5.17,5.95-10.27,12-15.43,17.91-2.73,3.15-5.45,6.31-8.32,9.33-7,7.36-14.77,14.91-22.11,21.88-6.86,6.53-13.8,13.09-22.1,18.14-8.26-4.89-16.31-9.92-23.33-16.44-2.62-2.42-5.4-4.68-8-7.15C94.31,257.8,88,248.76,81.25,239.32c-4.94-6.88-9.64-13.94-14.45-20.92-2.37,1.95-13.36,20.43-21.22,34.81-6.48,11.88-13.42,23.52-20.32,35.56a19.34,19.34,0,0,1-2.75-2c-3.78-4-7.77-7.85-11.2-12.14C5,266.82,1.76,257.79.39,247.75c-1.39-10.23,1.23-19.7,3.44-29.38,1.92-8.45,6.22-15.71,10.55-23,6.15-10.38,12-20.91,18.07-31.35,1.55-2.68,3.26-5.26,5-8,23.51,0,46.71,0,69.77.05-.19,3.27-.15,6.5-.19,9.27s1.69,5.3,3,7.92c6.31,12.69,13.48,25.45,19.71,38.18.65,1.34,1.35,2.65,2.34,4.6a53.86,53.86,0,0,0,3-4.7c7.06-12.26,12.53-23.07,19.58-35.34,1.9-3.29,3.8-6.72,3.42-10.66-.25-2.63,0-6.23,0-9a102.23,102.23,0,0,1,11.74-.24c20.3-.13,40.61-.06,60.91-.05.74,0,1.47.12,2.35.19,1.72,3,3.51,5.87,5.12,8.87q13.57,25.2,27.05,50.44a49.58,49.58,0,0,1,5.37,18.27c1.22,11.2-1.57,21.45-6,31.51-3.37,7.73-8.23,14.47-13.62,20.87C249.65,287.72,248.52,289.43,246.91,291.62Z"
            />
            <path
              className="cls-2"
              fill="url(#linear-gradient-2)"
              d="M158.26,136.33c0-5,0-9.14,0-13.31a16.3,16.3,0,0,0-1.24-6.5c-4-9.67-8.24-19.19-13.77-28.06a85.8,85.8,0,0,0-11-14.47C124.39,81.41,119,90.3,114.13,99.5c-2.91,5.51-5,11.45-7.31,17.27a12.81,12.81,0,0,0-.46,4.4c0,4.77,0,9.53,0,14.81-9.89.76-19.53.24-29.14.33s-19,0-29,0c0-4.19-.45-7.93.08-11.53a156.06,156.06,0,0,1,9.62-35.65c5.83-14.46,13.78-27.7,22.62-40.47a185,185,0,0,1,19.86-24c6.21-6.35,13.38-11.78,20.32-17.38,3.44-2.78,7.32-5,10.61-7.26,6,.81,30.14,21.6,37.84,30.19,27,30.13,42.66,65.52,49.09,105.81-5,.65-9.86.24-14.7.31-5.07.08-10.15,0-15.23,0H158.26Z"
            />
          </g>
        </g>
        {/* <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g> */}
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
