import {
  role,
  experience, 
  jobdescription,
  coverImage,
} from './assets';

// ----------------------------------------------------------------------

const _newmock = {
  id: (index) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7ba1${index + 1}`,
  role: (index) => role[index],
  experience: (index) => experience[index],
  description: (index) => jobdescription[index],
  image: {
    cover: (index) => coverImage[index],
    product: (index) => `https://api-dev-minimal-v4.vercel.app/assets/images/products/product_${index + 1}.jpg`,
    avatar: (index) => `https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_${index + 1}.jpg`,
  },
};

export default _newmock;
