import { randomNumberRange, randomInArray } from '../utils';
import _employeemock from '../_employeemock';

export const _employeeList = [...Array(5)].map((_, index) => ({
  id: _employeemock.id(index),
  name: _employeemock.name(index),
  phoneNumber: _employeemock.phoneNumber(index),
  email: _employeemock.email(index),
  designation: _employeemock.designation(index),
  salary: _employeemock.salary(index),
  dob: _employeemock.dob(index),
  gender: _employeemock.gender(index),
  address: _employeemock.address(index),
  profile: _employeemock.profile.cover(index),
  status: randomInArray(['trainee', 'onboard']),
}));
