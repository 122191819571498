export const gender =[
    "female",
    "female",
    "male",
    "male",
    "female",
    "female",
    "male",
    "female",
    "male",
    "female",
    "female",
    "male",
    "male",
    "female",
    "female",
    "male",
    "female",
    "male","female",
    "female",
    "male",
    "male",
    "female",
    "female",
    "male",
    "female",
    "male",
]