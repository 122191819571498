export const location=[
    "Ahmedabad",
    "Vadodara",
    "Surat",
    "Junagadh",
    "Pune",
    "Chennai",
    "Hydrabad",
    "Ahmedabad",
    "Vadodara",
    "Surat",
    "Junagadh",
    "Pune",
    "Chennai",
    "Hydrabad","Ahmedabad",
    "Vadodara",
    "Surat",
    "Junagadh",
    "Pune",
    "Chennai",
    "Hydrabad",
]