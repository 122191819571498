export const coverImage = [
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/meandone.jpg',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/meandone.jpg',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/Reactdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/Reactdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
    'https://www.aimbrill.com/images/technology/mernstackdone.png',
];
