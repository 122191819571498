import _newmock from '../_newmock';
import { randomNumberRange, randomInArray } from '../utils';

export const _jobList = [...Array(24)].map((_, index) => ({
  id: _newmock.id(index),
  role: _newmock.role(index),
  cover: _newmock.image.cover(index),
  jobExperience: _newmock.experience(index),
  description: _newmock.description(index),
  status: randomInArray(['active', 'banned']),
}));
