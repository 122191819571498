import {
    role,
    coverImage,
    fullName,
    phoneNumber,
    email,
    price,
    age,
    fullAddress,
    gender
  } from './assets';

  
  // ----------------------------------------------------------------------
  
  const _employeemock = {
    id: (index) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7ba1${index + 1}`,
    name:(index) => fullName[index],
    phoneNumber: (index) => phoneNumber[index],
    email: (index) => email[index],
    designation: (index) => role[index],
    salary: (index) => price[index],
    dob: (index) => age[index],
    gender:(index) => gender[index],
    address: (index) => fullAddress[index],
    profile: {
      cover: (index) => coverImage[index],
      product: (index) => `https://api-dev-minimal-v4.vercel.app/assets/images/products/product_${index + 1}.jpg`,
      avatar: (index) => `https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_${index + 1}.jpg`,
    },
  };
  
  export default _employeemock;
  