export const jobdescription = [
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." , 
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry." ,
]