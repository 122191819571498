export const experience = [
  '1-3 ',
  '3-5',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
  '1-3 ',
];
